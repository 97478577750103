import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Telas from "~/Util/telas";
import * as controllerUsuarios from "~/controller/controllerUsuarios.js";
import { useHotkeys } from 'react-hotkeys-hook';
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';
import {
  Card,
  Col,
  Button,
  ButtonGroup,
  CardBody,
  Input,
  Row,
  TabContent,
  TabPane,
  Label,
  FormGroup,
  Modal,
  ModalBody,
  CardFooter,
  Table,
} from "reactstrap";

//const avatar = require("~/assets/img/face-0.jpg");
const avatar = "https://integrador.ernetwork.com.br/img/face-0.jpg";
const Acesso = (props) => {
  const notificacao = useSelector(state => state.notificacao);
  const usuario = useSelector(state => state.login.usuario);
  const grupos = useSelector(state => state.grupos);
  const telas = useSelector(state => state.sistema.telas);
  const cadastro = useSelector(state => state.usuarios.cadastro);
  const dispatch = useDispatch();
  const [modalGrupos, setModalGrupos] = useState(false);
  const [modalExcluirGrupo, setModalExcluirGrupo] = useState(false);
  const [modalSalvar, setModalSalvar] = useState(false);
  const [modalVincular, setModalVincular] = useState(false);
  const [horizontalTabs, setHorizontalTabs] = useState({ codGrupoAcesso: 0, descricao: '' });
  const [usuarios, setUsuarios] = useState([]);
  const [grupoSelecionado, setGrupoSelecionado] = useState();

  useEffect(() => {
    if (props.salvar)
      toggleModalVincular();

  }, [props.salvar])

  useEffect(() => {
    Telas(dispatch);
  }, [])

  useEffect(() => {
    if (modalGrupos) {
      document.getElementById('nomeGrupo').focus();
    }
  }, [modalGrupos])

  //Quando as categorias dos grupos forem preenchidas seleciona a primeira automaticamente
  useEffect(() => {
    if (grupos.grupos.length !== 0) {
      if (cadastro.nomeGrupo !== "") {
        setHorizontalTabs({ codGrupoAcesso: cadastro.codGrupo, descricao: cadastro.nomeGrupo })
        return
      }
      if (horizontalTabs.descricao === "") {
        setHorizontalTabs({ codGrupoAcesso: grupos.grupos[0].codGrupoAcesso, descricao: grupos.grupos[0].descricao })
      }
    }
  }, [grupos.grupos, cadastro.nomeGrupo])

  useHotkeys('shift+v', async () => {
    toggleModalVincular()
  }, [cadastro, horizontalTabs])

  function changeActiveTab(e, codGrupoAcesso, descricao) {
    e.preventDefault();
    setHorizontalTabs({ codGrupoAcesso, descricao });
  };

  function toggleModalGrupos() {
    setModalGrupos(!modalGrupos);
    dispatch({ idStore: 'GRUPOS_ACESSO', type: 'NEW_GRUPO' });
  };

  function toggleModalExcluirGrupo() {
    setModalExcluirGrupo(!modalExcluirGrupo);
  };

  function toggleModalSalvar() {
    setModalSalvar(!modalSalvar);
  };

  function toggleModalVincular() {
    setModalVincular(!modalVincular);
  };

  function handleOnChange(value, type) {
    dispatch({ idStore: 'GRUPOS_ACESSO', type: type, value });
  }

  function handleCheckTelas(codGrupo, codTela) {
    let grupo = grupos.grupos;
    for (let i = 0; i < grupo.length; i++) {
      if (codGrupo === grupo[i].codGrupoAcesso) {
        for (let j = 0; j < grupo[i].telas.length; j++) {
          if (codTela === grupo[i].telas[j].codTela) {
            dispatch({ idStore: 'GRUPOS_ACESSO', type: 'CHANGE', grupoIndex: i, telaIndex: j })
          }
        }
      }
    }
  }

  function handleSalvarGrupo(e, codGrupoAcesso) {
    e.preventDefault();
    setGrupoSelecionado(codGrupoAcesso);
    toggleModalSalvar();
  }

  async function handleExcluirGrupo(e, codGrupoAcesso) {
    e.preventDefault();
    const response = await controllerUsuarios.GetUsuariosGrupo(notificacao, usuario.empresa.codEmpresa, codGrupoAcesso);
    setUsuarios(response);
    setGrupoSelecionado(codGrupoAcesso);
    toggleModalExcluirGrupo();
  }

  async function NovoGrupo(e) {
    e.preventDefault();
    if (grupos.newGrupo.nome.trim() === "") {
      document.getElementById('nomeGrupo').focus();
      dispatch({ idStore: 'GRUPOS_ACESSO', type: 'NOME_GRUPO', value: '' });
      return
    }
    var codGrupoAcesso = await controllerUsuarios.NovoGrupo(notificacao, usuario.empresa.codEmpresa, grupos.newGrupo, dispatch);
    setHorizontalTabs({ codGrupoAcesso: codGrupoAcesso, descricao: grupos.newGrupo.nome })
    toggleModalGrupos();
  }

  function SalvarGrupo() {
    controllerUsuarios.UpdateGrupo(notificacao, dispatch, usuario.empresa.codEmpresa, grupos.grupos, grupoSelecionado);
  }

  function ExcluirGrupo(e) {
    e.preventDefault();
    controllerUsuarios.ExcluirGrupo(notificacao, usuario.empresa.codEmpresa, grupoSelecionado, dispatch);
    setHorizontalTabs({ codGrupoAcesso: grupos.grupos[0].codGrupoAcesso, descricao: grupos.grupos[0].descricao })
  }

  async function VincularGrupo() {
    if (await controllerUsuarios.SalvarUsuarioGrupo(notificacao, dispatch, cadastro, horizontalTabs.codGrupoAcesso)) {
      setProximoCadastro(true);
      dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'codGrupo', value: horizontalTabs.codGrupoAcesso });
      dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'nomeGrupo', value: horizontalTabs.descricao });
      dispatch({ idStore: 'USUARIOS', type: 'STEP_WIZARD', value: 2 });
      setProximoCadastro(false);
    }
  }

  function EditarCadastro(user) {
    controllerUsuarios.GetUsuario(notificacao, usuario.empresa.codEmpresa, user.codUser, dispatch, () => {
      controllerUsuarios.GetGrupos(notificacao, dispatch, usuario.empresa.codEmpresa);
      toggleModalExcluirGrupo();
      dispatch({ idStore: 'USUARIOS', type: 'INICIO_CADASTRO', value: true });
    });
  }

  function setProximoCadastro(valor) {
    dispatch({ idStore: 'USUARIOS', type: 'PROXIMO_CADASTRO', value: valor });
  }

  return (
    <>
      <Row className="justify-content-center">
        <Col md="11">
          <h4 className="info-text">Selecione um grupo de acesso existente ou crie um novo</h4>
          <CardBody>
            <Row className="justify-content-center">

              <ButtonGroup>
                {grupos.grupos.map((grupo) => (
                  <Button key={grupo.codGrupoAcesso}
                    color={cadastro.nomeGrupo === grupo.descricao ? "success" :
                      horizontalTabs.descricao === grupo.descricao
                        ? "info"
                        : ""
                    }
                    type="button"
                    data-toggle="tab"
                    style={{ minWidth: '120px' }}
                    className={
                      horizontalTabs.descricao === grupo.descricao
                        ? "active"
                        : ""
                    }
                    onClick={e =>
                      changeActiveTab(e, grupo.codGrupoAcesso, grupo.descricao)
                    }
                  >
                    {grupo.descricao}
                  </Button>
                ))}
                <Button
                  type="button"
                  data-toggle="tab"
                  style={{ minWidth: '60px' }}
                  onClick={e => {
                    toggleModalGrupos();
                  }
                  }
                >
                  +
                </Button>
              </ButtonGroup>

            </Row>
            <Col className="col-md-6 ml-auto mr-auto">

              <TabContent
                className="tab-space"
                activeTab={horizontalTabs.descricao}
                style={{ marginBottom: '-50px', minHeight: '200px' }}
              >
                {grupos.grupos.map((grupo, key) => (
                  <TabPane key={key} tabId={grupo.descricao}>
                    <Card style={{ boxShadow: "0 0px 10px 0px rgba(0, 0, 0, 0.5)" }}>
                      <CardBody style={{ marginTop: '0px', minHeight: '150px' }}>
                        <Row className="justify-content-center">

                          {grupo.telas.map((tela, key) => (
                            <FormGroup check key={key}>
                              <Col style={{ width: '130px' }}>
                                <Label check>
                                  <Input type="checkbox"
                                    onChange={() => handleCheckTelas(grupo.codGrupoAcesso, tela.codTela)}
                                    checked={tela.status === 0 ? true : false}
                                  />
                                  <span className="form-check-sign" />{tela.nomeTela}
                                </Label>
                              </Col>
                            </FormGroup>
                          ))}
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <Row className="justify-content-center">
                          <Col className="text-center">
                            <Button color="danger" style={{ width: '130px' }} onClick={(e) => { handleExcluirGrupo(e, grupo.codGrupoAcesso) }}>Excluir</Button>
                          </Col>
                          <Col className="text-center">
                            <Button color="info" style={{ width: '130px' }} onClick={(e) => { handleSalvarGrupo(e, grupo.codGrupoAcesso) }}>Salvar</Button>
                          </Col>
                        </Row>
                      </CardFooter>
                    </Card>
                  </TabPane>
                ))}
              </TabContent>

            </Col>
          </CardBody>

          <CardFooter className="text-center">
            <Col>
              <ArrowTooltip title='Shift+V'><Button className="btn-padrao" color="info" onClick={() => toggleModalVincular()}>Vincular</Button></ArrowTooltip>
            </Col>
          </CardFooter>
        </Col>
      </Row>


      {/* Modal Novo Grupo */}
      <Modal
        isOpen={modalGrupos}
        toggle={toggleModalGrupos}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalGrupos}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">Novo Grupo</h6>
        </div>
        <ModalBody>

          <Row>

            <Col className="text-center">
              <label>Nome do grupo</label>
              <Input
                id="nomeGrupo"
                type="text"
                autoComplete="off"
                value={grupos.newGrupo.nome}
                onChange={e => handleOnChange(e.target.value, 'NOME_GRUPO')}
              />
            </Col>
          </Row>
          <CardBody>
            <Col className="text-center">
              <label>Telas Acessíveis pelo grupo</label>
            </Col>
            <Row className="justify-content-around">
              {telas.map((tela, key) => (
                <FormGroup check key={key}>
                  <Col style={{ width: '130px' }}>
                    <Label check >
                      <Input type="checkbox"
                        id={tela.codTela}
                        onChange={() => { dispatch({ idStore: 'GRUPOS_ACESSO', type: 'SET_TELA', value: tela.codTela }) }}
                      />
                      <span className="form-check-sign" />{tela.nomeTela}
                    </Label>
                  </Col>
                </FormGroup>
              ))}
            </Row>
          </CardBody>


        </ModalBody>
        <div className="modal-footer">
          <Button
            color="success"
            type="button"
            onClick={(e) => { NovoGrupo(e); }}
            style={{ width: '120px' }}
          >
            Pronto
                          </Button>
          <Button
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModalGrupos()}
            style={{ width: '120px' }}
          >
            Cancelar
                          </Button>
        </div>
      </Modal>
      {/* Modal Novo Grupo */}


      {/* Modal Excluir Grupo */}
      <Modal
        isOpen={usuarios ? modalExcluirGrupo : false}
        toggle={toggleModalExcluirGrupo}
      >
        {usuarios.length > 0 ? (
          <>
            <div className="modal-header justify-content-center">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModalExcluirGrupo}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Excluir grupo</h6>
            </div>
            <ModalBody>

              <Col className="text-center">
                <label>Os seguintes usuários estão vinculados a este grupo.<br></br> Por favor altere seus vínculos antes de continuar.</label>
              </Col>

              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th className="text-left">Foto</th>
                      <th className="text-left">Nome</th>
                      <th className="text-right">Editar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usuarios.map((usuario, key) => (
                      <tr key={key}>
                        <td className="text-left">
                          <div className="photo" style={{ float: 'left' }}>
                            <img
                              alt="..."
                              src={usuario.foto !== "" ? "data:image/jpg;base64," + usuario.foto : avatar}
                            />
                          </div>
                        </td>
                        <td className="text-left">{usuario.nome}</td>
                        <td className="text-right">
                          <Button
                            className="btn-link"
                            color="info"
                            size="sm"
                            onClick={() => EditarCadastro(usuario)}
                          >
                            <i className="tim-icons icon-pencil" />
                          </Button>
                        </td>
                      </tr>
                    ))
                    }
                  </tbody>
                </Table>
              </CardBody>


            </ModalBody>
            <div className="text-center">
              <Button
                color="success"
                type="button"
                onClick={(e) => { toggleModalExcluirGrupo(); }}
                style={{ width: '120px' }}
              >
                OK
                </Button>
            </div>
          </>
        ) :
          (
            <>
              <div className="modal-header justify-content-center">
                <button
                  aria-hidden={true}
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={toggleModalExcluirGrupo}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up">Excluir grupo</h6>
              </div>
              <ModalBody>

                <Col className="text-center">
                  <label>Tem certeza que deseja <b>excluir</b> o grupo?</label>
                </Col>

              </ModalBody>
              <CardBody>
                <Row className="justify-content-center">
                  <Col className="text-center">
                    <Button
                      color="info"
                      type="button"
                      onClick={(e) => { toggleModalExcluirGrupo(); }}
                      style={{ width: '150px' }}
                    >CANCELAR
                      </Button>
                  </Col>
                  <Col className="text-center">
                    <Button
                      color="danger"
                      type="button"
                      onClick={(e) => { toggleModalExcluirGrupo(); ExcluirGrupo(e); }}
                      style={{ width: '150px' }}
                    >EXCLUIR
                      </Button>
                  </Col>
                </Row>
              </CardBody>
            </>
          )
        }
      </Modal>
      {/* Modal Excluir Grupo*/}

      {/* Modal Salvar */}
      <Modal
        isOpen={modalSalvar}
        toggle={toggleModalSalvar}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalSalvar}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">Salvar alterações</h6>
        </div>
        <ModalBody>

          <Col className="text-center">
            <label>Todos os usuários vinculados a esse grupo terão seus acessos alterados. Tem certeza que deseja salvar o grupo?</label>
          </Col>

        </ModalBody>
        <CardBody>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Button
                color="default"
                type="button"
                onClick={(e) => { toggleModalSalvar(); }}
                style={{ width: '150px' }}
              >CANCELAR
                      </Button>
            </Col>
            <Col className="text-center">
              <Button
                color="info"
                type="button"
                onClick={(e) => { toggleModalSalvar(); SalvarGrupo(); }}
                style={{ width: '150px' }}
              >SALVAR
                      </Button>
            </Col>
          </Row>
        </CardBody>
      </Modal>
      {/* Modal Salvar*/}

      {/* Modal Vincular */}
      <Modal
        isOpen={modalVincular}
        toggle={toggleModalVincular}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalVincular}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">Vinculação de Usuário</h6>
        </div>
        <ModalBody>

          <Col className="text-center">
            <label>Deseja vincular <b>{cadastro.apelido}</b> ao grupo <b>{horizontalTabs.descricao}</b>?</label>
          </Col>

        </ModalBody>
        <CardBody>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Button
                color="default"
                type="button"
                onClick={(e) => { toggleModalVincular(); }}
                style={{ width: '150px' }}
              >CANCELAR
                      </Button>
            </Col>
            <Col className="text-center">
              <Button
                color="info"
                type="button"
                onClick={() => { toggleModalVincular(); VincularGrupo(); }}
                style={{ width: '150px' }}
              >VINCULAR
                      </Button>
            </Col>
          </Row>
        </CardBody>
      </Modal>
      {/* Modal Vincular*/}
    </>
  );
}

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salvar: false
    };
  }

  isValidated = () => {
    if (this.props.cadastro.codGrupo !== '') {
      return true
    }
    this.setState({ salvar: true }, () => {
      this.setState({ salvar: false })
    })
    return false
  }
  render() {
    return (
      <Acesso salvar={this.state.salvar} />
    );
  }
}
export default Wizard;

import React from "react";
import { useDispatch, useSelector } from "react-redux"
import { alertar } from "~/Util/alertar";
import * as controllerUsuarios from "~/controller/controllerUsuarios"
import { Select } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';
import {
  Input,
  Row,
  Col,
  CardBody,
  FormGroup,
  CardFooter,
  Button,
} from "reactstrap";

import { InputCpfCnpj, InputTelefone } from "~/components/ErnComponets/ErnComponets";

const generos = [
  { value: "M", label: "Masculino" },
  { value: "F", label: "Feminino" },
  { value: "O", label: "Outro" },
];

const Funcionarios = () => {
  const dispatch = useDispatch();
  const { cadastro, cadastroCopia, invalido } = useSelector(state => state.usuarios);
  const notificacao = useSelector(state => state.notificacao)

  function onChange(event, stateName) {
    dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: stateName, value: event.target.value });
    dispatch({ idStore: 'USUARIOS', type: 'INVALIDO', campo: stateName, value: false });
  };

  function onChangeGenero(value) {
    dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'genero', value: value.value });
  }

  function Salvar(e) {
    e.preventDefault();
    controllerUsuarios.SalvarFuncionario(notificacao, dispatch, cadastro, cadastroCopia);
  };

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore: 'USUARIOS', type: 'CLEAN_CADASTRO' });
    dispatch({ idStore: 'USUARIOS', type: 'CLEAN_INVALIDO' });
    dispatch({ idStore: 'USUARIOS', type: 'INICIO_CADASTRO', value: true });
  };

  return (
    <>
      <Row className="justify-content-center">
        <Col>
          <h4 className="info-text">Preencha com as informações do funcionário</h4>
          <CardBody>
            <Row className="justify-content-center">

              <Col md="6">

                <Row>
                  <Col>
                    <h5>Informações básicas</h5>
                  </Col>
                </Row>

                <Row>

                  <Col md="6">
                    <label>Nome <span className="error">*</span></label>
                    <FormGroup >
                      <Input
                        id="nomeFuncionario"
                        name="nome"
                        type="text"
                        value={cadastro.nome}
                        disabled={true}
                        onChange={e => onChange(e, "nome")}
                        maxLength="55"
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <label>CPF <span className="error">*</span></label>
                    <FormGroup >
                      <InputCpfCnpj
                        name="cpf"
                        type="text"
                        value={cadastro.cpf}
                        disabled={true}
                        onChange={e => onChange(e, "cpf")}
                        maxLength="14"
                      />
                    </FormGroup>
                  </Col>

                </Row>
                <br></br>

                <Row>

                  <Col md="6">
                    <label>E-mail do funcionário<span className="error">*</span></label>
                    <FormGroup className={invalido.emailFunc ? "has-danger" : ''}>
                      <Input
                        id="emailFuncionario"
                        type="text"
                        value={cadastro.emailFunc}
                        onChange={e => onChange(e, "emailFunc")}
                        maxLength="60"
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <label>Telefone</label>
                    <FormGroup >
                      <InputTelefone
                        name="telefone"
                        type="fone"
                        value={cadastro.telefone}
                        onChange={e => onChange(e, "telefone")}
                      />
                    </FormGroup>
                  </Col>

                </Row>
                <br></br>

                <Row>

                  <Col md="6">
                    <label>Data de nascimento</label>
                    <FormGroup className={invalido.dtNascimento ? "has-danger" : ''}>
                      <Input
                        name="dtNascimento"
                        type="date"
                        value={cadastro.dtNascimento}
                        onChange={e => onChange(e, "dtNascimento")}
                        maxLength="10"
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <label>Sexo</label>
                    <Select
                      id="tipoContribuinteSelect"
                      value={{ value: cadastro.genero }}
                      isSearchable={false}
                      onChange={value => onChangeGenero(value)}
                      options={generos}
                      autoComplete="off"
                    />
                  </Col>

                </Row>
                <br></br>

              </Col>

              <Col md="3">

                <Row>
                  <Col>
                    <h5>Informações adicionais</h5>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <label>RG</label>
                    <FormGroup >
                      <Input
                        id="rg"
                        name="rg"
                        type="text"
                        value={cadastro.rg}
                        onChange={e => onChange(e, "rg")}
                        maxLength="15"
                      />
                    </FormGroup>
                    <br></br>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <label>Orgão Emissor</label>
                    <FormGroup >
                      <Input
                        name="orgaoEmissor"
                        type="text"
                        value={cadastro.orgaoEmissor}
                        onChange={e => onChange(e, "orgaoEmissor")}
                        maxLength="5"
                      />
                    </FormGroup>
                    <br></br>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <label>Data de emissao</label>
                    <FormGroup className={invalido.dtEmissao ? "has-danger" : ''}>
                      <Input
                        name="dtEmissao"
                        type="date"
                        value={cadastro.dtEmissao}
                        onChange={e => onChange(e, "dtEmissao")}
                        maxLength="10"
                      />
                    </FormGroup>
                    <br></br>
                  </Col>
                </Row>

              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-center">
            <Col>
              <ArrowTooltip title='Shift+L'><Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Limpar</Button></ArrowTooltip>
              <ArrowTooltip title='Shift+S'><Button className="btn-padrao" color="info" onClick={(e) => Salvar(e)}>Salvar</Button></ArrowTooltip>
            </Col>
          </CardFooter>
        </Col>
      </Row>
    </>
  );
}

class Wizard extends React.Component {
  focusInput = () => {
    if (this.props.cadastro.codUser !== '') {
      setTimeout(() => {
        document.getElementById('emailFuncionario').focus()
      }, 100)
    } else {
      setTimeout(() => {
        document.getElementById('nomeFuncionario').focus()
      }, 100)
    }
  }

  isValidated = () => {
    if (this.props.cadastro.codUser !== '') {
      return true
    }
    alertar(this.props.notificacao, 'br', "Efetue o cadastro do funcionário para continuar", 'info', 'icon-bulb-63', 2);
    return false
  }
  render() {
    return (
      <Funcionarios />
    );
  }
}

export default Wizard;

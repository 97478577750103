import axios from "axios";
import api from "~/Util/api.js";
import history from "~/Util/history";
import { alertar } from "~/Util/alertar";
import { campoValido } from "~/Util/campoValido";
import * as controllerDashboard from "~/controller/controllerDashboard.js"

//var basicAuth = "Basic " + btoa(api.apiUser + ":" + api.apiPass);
var idStore = "LOGIN";
//const avatar = require("~/assets/img/face-0.jpg");
const avatar = "https://integrador.ernetwork.com.br/img/face-0.jpg";

export function login(login, notificacao, dispatch, codEmpresa) {
  try {
    if (!campoValido(login.usuario.login)) {
      document.getElementById("login").focus();
      alertar(notificacao, "br", "Digite um Login ou e-mail", "info", "icon-bulb-63", 2);
      return;
    }

    if (!campoValido(login.usuario.senha)) {
      document.getElementById("senha").focus();
      alertar(notificacao, "br", "Digite uma senha", "info", "icon-bulb-63", 2);
      return;
    }

    autenticarAdmin(login, notificacao, dispatch);

  } catch (e) {
    alertar(notificacao, "br", "Não foi possível concluir", "warning", "icon-alert-circle-exc", 2);
  }
}

//Desloga o usuario e volta para a tela inicial
export function logout(dispatch) {
  dispatch({ idStore, type: "LOGOUT" });
  dispatch({ idStore: "USUARIOS", type: "CLEAN_CADASTRO" });
  dispatch({ idStore: "USUARIOS", type: "CLEAN_SEARCH" });
  window.localStorage.removeItem("USUARIO");
  window.localStorage.removeItem("TOKEN_KEY");
  window.localStorage.removeItem("CHART1");
  window.localStorage.removeItem("CHART_SEMANAL");
  window.localStorage.removeItem("CHART_DIARIO");
  window.localStorage.removeItem("DEPARTAMENTOS");
  
  window.localStorage.removeItem("CHART_USUARIO_PERFIL");
  window.localStorage.removeItem("QTD_USUARIO_PERFIL");
  window.localStorage.removeItem("CHART_CURSOS");
  window.localStorage.removeItem("QTD_CURSOS");
  window.localStorage.removeItem("PERFIS");
  window.localStorage.removeItem("USUARIOS_ATIVOS");
  window.localStorage.removeItem("ERROS");
  window.localStorage.removeItem("ULTIMAS_INTEGRACOES");
  window.localStorage.removeItem("ULTIMOS_ERROS");
  history.push("/auth/login");
}

//Deixa na tela de Login com o usuário e esperando a senha
export function lock(dispatch) {
  dispatch({ idStore, type: "LOCK" });
  //dispatch({ idStore: 'USUARIOS', type: 'CLEAN_CADASTRO' });
  //dispatch({ idStore: 'USUARIOS', type: 'CLEAN_SEARCH' });
  //window.localStorage.removeItem('USUARIO');
  window.localStorage.removeItem('TOKEN_KEY');
 // window.localStorage.removeItem('TOPFIVE')
  history.push('/auth/login');
}

//Autentica o usuário
function autenticarAdmin(login, notificacao, dispatch) {
  try {
      dispatch({ idStore, type: 'OBJETOS', campo: 'percentage2', value: 25 });
      axios({
          method: 'post',
          url: api.urlIntegradorApi + 'login/authenticate',
          //url: 'http://prod.ernetwork.com.br:5555/login/authenticate',
          headers: {"Content-Type": "application/json",},
          data: {
              email: login.usuario.login,
              senha: login.usuario.senha,
          }
      }).then(response => {
          dispatch({ idStore, type: 'OBJETOS', campo: 'percentage2', value: 100 });
          var mensagemRetorno = response.data.message;
          if (mensagemRetorno === "Sucesso"){
            var user = response.data.data;
            dispatch({ idStore, type: 'USUARIO', campo: 'idUsuarioLogin', value: user.idUsuarioLogin })
            dispatch({ idStore, type: 'USUARIO', campo: 'login', value: user.login })
            dispatch({ idStore, type: 'USUARIO', campo: 'email', value: user.email })
            dispatch({ idStore, type: 'USUARIO', campo: 'nome', value: user.nome })
            dispatch({ idStore, type: 'USUARIO', campo: 'empresa', value: user.empresa })
            dispatch({ idStore, type: 'USUARIO', campo: 'token', value: user.token })
            if (user.foto != null){
              dispatch({ idStore, type: 'USUARIO', campo: 'foto', value: user.foto })
            }else{
              dispatch({ idStore, type: 'USUARIO', campo: 'foto', value: avatar })
            }
            
            dispatch({ idStore, type: 'OBJETOS', campo: 'loginInvalido', value: false });
  
            //Armazena TOKEN localmente
            dispatch({ idStore, type: 'USUARIO', campo: 'usuarioExiste', value: true })
            dispatch({ idStore, type: 'USUARIO', campo: 'loginSucesso', value: true })
            dispatch({ idStore, type: 'SAVE' })
            window.localStorage.setItem("TOKEN_KEY", user.token)
  
            //Busca dados para o Dashboard
            controllerDashboard.Pesquisar(dispatch, user);
  
            alertar(notificacao, 'br', "Bem vindo!", 'success', 'icon-check-2', 2)
            setTimeout(() => { history.push('/Dashboard') }, 1000)

          }else{
            alertar(notificacao, 'br', mensagemRetorno , 'warning', 'icon-alert-circle-exc', 2);
            dispatch({ idStore, type: 'OBJETOS', campo: 'senhaInvalida', value: true });
            document.getElementById("senha").focus()
            setTimeout(() => { dispatch({ idStore, type: 'OBJETOS', campo: 'percentage2', value: 0 }); }, 1000)  
          }


      }).catch((erro) => {
          var mensagemErro = erro.response.data;
          alertar(notificacao, 'br', erro.response ? mensagemErro.mensagem : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, erro)
          dispatch({ idStore, type: 'OBJETOS', campo: 'senhaInvalida', value: true });
          document.getElementById("senha").focus()
          setTimeout(() => { dispatch({ idStore, type: 'OBJETOS', campo: 'percentage2', value: 0 }); }, 500)

      });

  } catch (e) {

      alertar(notificacao, 'br', "Falha no login", 'warning', 'icon-alert-circle-exc', 2)
      dispatch({ idStore, type: 'OBJETOS', campo: 'senhaInvalida', value: true });
      document.getElementById("senha").focus()
      setTimeout(() => { dispatch({ idStore, type: 'OBJETOS', campo: 'percentage2', value: 0 }); }, 500)

  }
}

/******************************************************************
 * Valida Token 
 ******************************************************************/
export async function ValidarToken(token) {
  try {

    //var urlValidar = api.urlIntegradorApi + api.urlValidarToken + `${token}`;
    /* axios({
      method: 'get',
      url: urlValidar,
      headers: {"Content-Type": "application/json",},
  }).then(response => {
      var token = response.data.data;
      if (token.Valido === "S"){
        return true;
      }

      return false;

  }).catch((erro) => {
    return false;
    //alertar(notificacao, 'br', "Não foi possível validar o acesso", 'warning', 'icon-alert-circle-exc', 2)
  }); */

      //dispatch({ idStore, type: 'EMPRESAS', value: [] })

     //const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
     var urlValidar = api.urlIntegradorApi + api.urlValidarToken + `${token}`;
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: urlValidar,
        headers: {}
      };
      
      await axios.request(config)
      .then((response) => {
        var token = response.data.data;
        if (token.Valido === "S"){
          return true;
        }
  
        return false;  
      })
  
  } catch (e) {
      return false;
      //alertar(notificacao, 'br', "Não foi possível validar o acesso", 'warning', 'icon-alert-circle-exc', 2)
  }
}



/* function selectEmpresa(empresas, notificacao, dispatch, token) {
  //Caso exista só uma empresa
  dispatch({ idStore, type: "OBJETOS", campo: "primeiroAcesso", value: false });
  if (empresas.length === 0) {
    alertar(
      notificacao,
      "br",
      "Você não está vinculado a uma empresa",
      "warning",
      "icon-alert-circle-exc",
      2
    );
  } else if (empresas.length === 1) {
    axios({
      method: "post",
      url: api.urlCadastro + "api/v1/usuario/sessao",
      headers: { Authorization: "Basic " + btoa(api.apiUser + ":" + token) },
      data: {
        CodEmpresa: empresas[0].codEmpresa,
      },
    })
      .then((response) => {
        dispatch({
          idStore,
          type: "USUARIO",
          campo: "loginSucesso",
          value: true,
        });
        dispatch({
          idStore,
          type: "USUARIO",
          campo: "empresa",
          value: empresas[0],
        });
        dispatch({ idStore, type: "SAVE" });
        window.localStorage.setItem("TOKEN_KEY", response.data.records);
        alertar(notificacao, "br", "Bem vindo!", "success", "icon-check-2", 2);
        setTimeout(() => {
          history.push("/admin/Dashboard");
        }, 1000);
      })
      .catch((erro) => {
        alertar(
          notificacao,
          "br",
          "Não foi possível iniciar a sessão",
          "warning",
          "icon-alert-circle-exc",
          2,
          erro
        );
      });
  } else if (empresas.length > 1) {
    dispatch({ idStore, type: "USUARIO", campo: "loginSucesso", value: true });
    setTimeout(() => {
      document.getElementById("empresa1").focus();
    }, 500);
  }
}
 */
export function salvarSenha(login, notificacao, dispatch) {
  function camposInvalidos() {
      dispatch({ idStore, type: 'OBJETOS', campo: 'novaSenhaInvalida', value: true });
      dispatch({ idStore, type: 'OBJETOS', campo: 'confirmarSenhaInvalida', value: true });
  }
  try {
      if (login.objetos.novaSenha === "" && login.objetos.confirmarSenha === "") {
          camposInvalidos();
          return alertar(notificacao, 'br', "Preencha os dois campos", 'info', 'icon-bulb-63', 2);
      }
      if (login.objetos.novaSenha !== login.objetos.confirmarSenha) {
          camposInvalidos();
          return alertar(notificacao, 'br', "As senhas digitadas devem ser iguais", 'warning', 'icon-alert-circle-exc', 2);
      }
      axios({
          method: 'put',
          url: api.urlCadastro + `api/v1/usuario/${login.usuario.primeiroAcesso ? 'primeiroAcesso' : 'alterarSenha'}`,
          //headers: { Authorization: basicAuth },
          data: {
              CodUser: login.usuario.codUser,
              Senha: login.objetos.novaSenha
          }
      }).then(function (response) {

          alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2);

          //Verifica a quantidade de empresas na qual o usuário está viculado
          //selectEmpresa(login.usuario.empresas, notificacao, dispatch, login.usuario.token)

      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
  }
}

export function NovaSenha(notificacao, dispatch, novaSenha) {
  try {
      if (novaSenha.login.trim(" ") === "") {
          dispatch({ idStore, type: 'NOVA_SENHA', campo: 'login', value: "" });
          document.getElementById('loginEsqueciSenha').focus();
          return alertar(notificacao, 'br', "Preencha o campo", 'info', 'icon-bulb-63', 2);
      }
      axios({
          method: 'put',
          url: api.urlCadastro + `api/v1/usuario/novaSenha?login=${novaSenha.login}`,
          //headers: { Authorization: basicAuth },
      }).then(function (response) {

          alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 5);
          setTimeout(() => {
              history.push('/auth/login');
          }, 1000);

      }).catch(e => {
          if (e.response.data.message.match(/inválido/)) {
              dispatch({ idStore, type: 'NOVA_SENHA', campo: 'loginInvalido', value: true });
          }
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e);
  }
}

import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerICMSs from "~/controller/controllerICMSs";
import { Select } from "~/components/ErnComponets/ErnComponets";
import { maskICMS } from "~/Util/mascaras";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip'; 
import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,

} from "reactstrap";

const idStore = "ICMS";



const IMCS = () => {
  const notificacao = useSelector(state => state.notificacao);
  const { codEmpresa } = useSelector(state => state.login.usuario.empresa);
  const { ICMS, ICMSCopia, invalido, regimes } = useSelector(state => state.ICMSs);
  const dispatch = useDispatch();

  useEffect(() => {
    controllerICMSs.GetRegimes(notificacao, dispatch, codEmpresa)
    dispatch({ idStore, type: 'REGIMES_PESQ', value: [] })

  }, [])

  function onChange(value, stateName) {
    dispatch({ idStore, type: 'ICMS', campo: stateName, value: value });
    dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
  };
  function onChangeRegime(value) {
    dispatch({ idStore, type: 'ICMS', campo: 'codRegime', value: value.value });
    dispatch({ idStore, type: 'ICMS', campo: 'regime', value: value.label });
  }

  async function Salvar(e) {
    e.preventDefault();
    if (ICMS.codICMS === '') {
      controllerICMSs.CreateICMS(notificacao, dispatch, codEmpresa, ICMS);
    } else {
      controllerICMSs.UpdateICMS(notificacao, dispatch, codEmpresa, ICMS, ICMSCopia);
    }
  };

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_ICMS' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    document.getElementById('cadnome').focus()
  };


  return (
    <>
      <Row className="justify-content-center">
        <Col>
          <h4 className="info-text">Preencha com as informações do ICMS</h4>
          <CardBody>

            <Row className="justify-content-center">
              <Col md="10">

                <Row>

                  <Col lg="4" md="3">
                    <label>Regime</label>
                    <FormGroup>
                      <Select
                        id="pesqRegimesSelect"
                        first={'Selecione'}
                        value={{ value: ICMS.codRegime, label: ICMS.regime }}
                        select={{ value: "codRegime", label: "descricao" }}
                        options={regimes}
                        isSearchable={true}
                        onChange={value => onChangeRegime(value)}
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg md sm="7">
                    <label>ICMS</label>
                    <FormGroup className={invalido.numeroICMS ? "has-danger" : ''}>
                      <Input
                        id="cadnome"
                        type="text"
                        value={ICMS.numeroICMS}
                        onChange={e => onChange(maskICMS(e.target.value), 'numeroICMS')}
                        autoComplete="off"
                        maxLength="5"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

                <Row>

                  <Col lg md sm="7">
                    <label>Descrição</label>
                    <FormGroup className={invalido.descricao ? "has-danger" : ''}>
                      <Input
                        id="descricao"
                        type="text"
                        value={ICMS.descricao}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="50"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

              </Col>

            </Row>

          </CardBody>
          <CardFooter className="text-center">
            <Row className="justify-content-center">
              <Col md="10" >
                <Col className="text-center">
                <ArrowTooltip title='shift+L'><Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Limpar</Button></ArrowTooltip>
                <ArrowTooltip title='shift+S'><Button className="btn-padrao" color="info" onClick={(e) => Salvar(e)}>Salvar</Button></ArrowTooltip>
                </Col>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>





    </>
  )
};

class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      document.getElementById('cadnome').focus()
    }, 100)
  }

  render() {
    return (
      <IMCS />
    )
  }
}

export default Wizard;

/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}
        style={{ paddingBottom: "15px" }}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <div className="copyright">
            © {new Date().getFullYear()} por{" "}
            <a
              //href="http://www.ernsistemas.com.br"
              href="#"
              target="_blank"
              style={{ color: "#d6a956", textShadow: "0px 0px 6px black" }}
            >
              US4 Tecnologia
            </a>{" "}
            {/* e Tecnologia */}
          </div>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertar } from "~/Util/alertar";
import ReactWizard from "~/components/Wizard/Wizard";
import ReactTable from "react-table-6";
import * as controllerUsuarios from "~/controller/controllerUsuarios";
import classNames from "classnames";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import ArrowTooltip from "~/components/Tooltip/ArrowTooltip";
import { InputCpfCnpj } from "~/components/ErnComponets/ErnComponets";
import {
  Card,
  Col,
  Button,
  ButtonGroup,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Row,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  FormGroup,
  Label,
} from "reactstrap";

// wizard steps
import Step1 from "~/views/Usuarios/CadastroSteps/Usuario.jsx";
import Step2 from "~/views/Usuarios/CadastroSteps/Acesso.jsx";
import Step3 from "~/views/Usuarios/CadastroSteps/Funcionario.jsx";
import { useHotkeys } from "react-hotkeys-hook";

var steps = [
  {
    stepName: "Usuário",
    stepIcon: "tim-icons icon-single-02",
    component: Step1,
    stepProps: {},
  },
  {
    stepName: "Acesso",
    stepIcon: "tim-icons icon-settings-gear-63",
    component: Step2,
    stepProps: {},
  },
  {
    stepName: "Funcionário",
    stepIcon: "tim-icons icon-single-copy-04",
    component: Step3,
    stepProps: {},
  },
];
const idStore = "USUARIOS";

const Usuarios = () => {
  const notificacao = useSelector((state) => state.notificacao);
  const { codEmpresa } = useSelector((state) => state.login.usuario.empresa);
  const {
    cadastro,
    cadastroCopia,
    pesquisa,
    usuarios,
    inicioCadastro,
    proximoCadastro,
  } = useSelector((state) => state.usuarios);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [dataTable, setDataTable] = useState([]);
  const [table, setTable] = useState(renderTable([]));
  const [horizontalTabs, setHorizontalTabs] = useState("pesquisar");
  const [tableVisible, setTableVisible] = useState(false);
  const [modalInativar, setModalInativar] = useState(false);
  const [userSelected, setUserSelected] = useState({ status: 0 });
  const [width, setWidht] = useState(window.innerWidth);

  const [stepActive, setStepActive] = useState(0);
  const { empresa } = useSelector((state) => state.login.usuario);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  function updateWidth() {
    setWidht(window.innerWidth);
  }
  function changeActiveStep(valor) {
    setStepActive(valor);
  }

  useEffect(() => {
    setDataTable(tableData(usuarios));
    if (usuarios.length > 0) {
      setTableVisible(true);
    } else {
      setTableVisible(false);
    }
  }, [usuarios]);

  useEffect(() => {
    setTable(renderTable());
  }, [dataTable, loading]);

  useEffect(() => {
    steps.forEach((step) => {
      step.stepProps = { ...step.stepProps, notificacao };
    });
  }, [notificacao]);

  useEffect(() => {
    steps.forEach((step) => {
      step.stepProps = { ...step.stepProps, cadastro: cadastro };
    });
    setInicioCadastro(false);
  }, [cadastro]);

  useEffect(() => {
    if (inicioCadastro) setInicioCadastro(false);
  }, [inicioCadastro]);

  useHotkeys(
    "shift+1",
    () => {
      changeActiveTab("pesquisar");
    },
    []
  );

  useHotkeys(
    "shift+2 ",
    () => {
      changeActiveTab("cadastrar");
      novoCadatro();
    },
    []
  );

  useHotkeys(
    "shift+p",
    () => {
      if (horizontalTabs === "pesquisar") {
        controllerUsuarios.Pesquisar(
          notificacao,
          codEmpresa,
          pesquisa,
          dispatch
        );
      }
    },
    [codEmpresa, pesquisa]
  );

  useHotkeys(
    "shift+l",
    () => {
      if (horizontalTabs === "pesquisar") {
        handleLimpar();
      } else if (stepActive === 0) {
        dispatch({ idStore: "USUARIOS", type: "CLEAN_CADASTRO" });
        dispatch({ idStore: "USUARIOS", type: "CLEAN_INVALIDO" });
      } else if (stepActive === 2) {
        dispatch({ idStore: "USUARIOS", type: "CLEAN_CADASTRO" });
        dispatch({ idStore: "USUARIOS", type: "CLEAN_INVALIDO" });
        dispatch({ idStore: "USUARIOS", type: "INICIO_CADASTRO", value: true });
      }
    },
    [horizontalTabs, stepActive]
  );

  useHotkeys(
    "shift+s",
    () => {
      if (stepActive === 0 && cadastro.codFunc === "") {
        dispatch({
          idStore: "USUARIOS",
          type: "CADASTRO",
          campo: "codEmpresa",
          value: empresa.codEmpresa,
        });
        async function salvar() {
          var usuarioExiste = await controllerUsuarios.SalvarUsuario(
            notificacao,
            dispatch,
            cadastro,
            empresa.codEmpresa
          );
          if (usuarioExiste === false) {
            dispatch({
              idStore: "USUARIOS",
              type: "PROXIMO_CADASTRO",
              value: true,
            });
            setTimeout(() => {
              dispatch({
                idStore: "USUARIOS",
                type: "PROXIMO_CADASTRO",
                value: false,
              });
            }, 100);
          }
        }
        salvar();
      } else if (stepActive === 2) {
        controllerUsuarios.SalvarFuncionario(
          notificacao,
          dispatch,
          cadastro,
          cadastroCopia
        );
      }
    },
    [stepActive, cadastro, empresa]
  );

  function tableData(data) {
    return data.map((prop, key) => {
      return {
        id: key,
        codUser: prop["codUser"],
        nome: prop["nome"],
        email: prop["email"],
        cpf: prop["cpf"],
        dtCadastro: prop["dtCadastro"],
        status: prop["status"],
      };
    });
  }

  function renderTable() {
    return dataTable.map((obj, key) => {
      return {
        ...dataTable[key],
        actions: (
          <div className="actions-right">
            {!loading ? (
              <ArrowTooltip title="Editar">
                <Button
                  id={`editarUsuario-${key}`}
                  onClick={() => {
                    handleEditarCadastro(obj);
                  }}
                  size="sm"
                  className={classNames("btn-icon btn-link")}
                  color="success"
                >
                  <Icon icon="edit" className="fa-lg" />
                </Button>
              </ArrowTooltip>
            ) : (
              <Button
                id={`editarUsuario-${key}`}
                onClick={() => {
                  handleEditarCadastro(obj);
                }}
                size="sm"
                className={classNames("btn-icon btn-link")}
                color="success"
                disabled={true}
              >
                <Icon icon="edit" className="fa-lg" />
              </Button>
            )}
            <ArrowTooltip
              title={
                obj.status === 0 || obj.status === 7 ? "Inativar" : "Reativar"
              }
            >
              <Button
                id={`inativarUsuario-${key}`}
                onClick={() => {
                  handleInativarUsuario(obj);
                }}
                className="btn-icon btn-link"
                color="info"
              >
                {obj ? (
                  obj.status === 0 || obj.status === 7 ? (
                    <Icon icon="ban" className="fa-lg" />
                  ) : (
                    <Icon icon="redo" className="fa-lg" />
                  )
                ) : null}
              </Button>
            </ArrowTooltip>
          </div>
        ),
      };
    });
  }

  function changeActiveTab(tabName) {
    setHorizontalTabs(tabName);
    if (tabName === "pesquisar") {
      setTimeout(() => {
        document.getElementById("nome").focus();
      }, 100);
    }
  }

  function handleOnChange(e, id, type) {
    dispatch({ idStore, type, campo: id, value: e.target.value });
  }

  function handleOnChangeCheck(value) {
    dispatch({ idStore, type: "PESQUISA", campo: "ativos", value });
    controllerUsuarios.Pesquisar(
      notificacao,
      codEmpresa,
      { ...pesquisa, ativos: value },
      dispatch
    );
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      controllerUsuarios.Pesquisar(notificacao, codEmpresa, pesquisa, dispatch);
    }
  }

  function handlePesquisarUser(e) {
    e.preventDefault();
    controllerUsuarios.Pesquisar(notificacao, codEmpresa, pesquisa, dispatch);
  }

  function handleLimpar() {
    dispatch({ idStore, type: "CLEAN_SEARCH" });
  }

  async function handleEditarCadastro(obj) {
    setLoading(true);

    if (!loading) {
      dispatch({ idStore: "USUARIOS", type: "CLEAN_INVALIDO" });
      await controllerUsuarios.GetUsuario(
        notificacao,
        codEmpresa,
        obj.codUser,
        dispatch,
        () => {
          setHorizontalTabs("cadastrar");
          controllerUsuarios.GetGrupos(notificacao, dispatch, codEmpresa);
          setInicioCadastro(true);
        }
      );
    }
    setLoading(false);
  }

  function handleInativarUsuario(obj) {
    setUserSelected(obj);
    toggleModalInativar();
  }

  async function novoCadatro() {
    setInicioCadastro(true);
    await controllerUsuarios.GetGrupos(notificacao, dispatch, codEmpresa);
    dispatch({ idStore: "USUARIOS", type: "CLEAN_CADASTRO" });
    dispatch({ idStore: "USUARIOS", type: "CLEAN_INVALIDO" });
    dispatch({
      idStore: "USUARIOS",
      type: "CADASTRO",
      campo: "codEmpresa",
      value: codEmpresa,
    });
  }

  async function inativarUsuario() {
    const cadastroPendente = await controllerUsuarios.InativarUsuario(
      notificacao,
      dispatch,
      userSelected,
      codEmpresa,
      pesquisa
    );
    if (cadastroPendente) {
      handleEditarCadastro(userSelected);
    }
  }

  function setInicioCadastro(valor) {
    dispatch({ idStore: "USUARIOS", type: "INICIO_CADASTRO", value: valor });
  }

  async function finishButtonClick() {
    const error = await controllerUsuarios.SalvarFuncionario(
      notificacao,
      dispatch,
      cadastro,
      cadastroCopia
    );

    if (!error) {
      dispatch({ idStore: "USUARIOS", type: "CLEAN_CADASTRO" });
      alertar(
        notificacao,
        "br",
        "Cadastro concluído",
        "success",
        "icon-check-2",
        2
      );
      setInicioCadastro(true);
    }

    return error;
  }

  function toggleModalInativar() {
    setModalInativar(!modalInativar);
  }

  return (
    <>
      <div className="content">
        <Col className="mr-auto ml-auto" md="11">
          <Card className="">
            <CardHeader className="text-center">
              <CardTitle tag="h3">Usuários</CardTitle>
              <TabContent
                className="tab-space"
                activeTab={horizontalTabs}
                style={{ marginTop: "-30px", marginBottom: "-70px" }}
              >
                <TabPane tabId="pesquisar">
                  <h4>Pesquisa de usuários</h4>
                </TabPane>
                <TabPane tabId="cadastrar">
                  <h4>Cadastro de usuários</h4>
                </TabPane>
              </TabContent>
            </CardHeader>

            <CardBody>
              <ButtonGroup style={{ width: "100%" }}>
                <ArrowTooltip title="shift+1">
                  <Button
                    color={horizontalTabs === "pesquisar" ? "info" : ""}
                    type="button"
                    data-toggle="tab"
                    className={classNames(
                      "btn-gp-padrao",
                      horizontalTabs === "pesquisar" ? "active" : ""
                    )}
                    onClick={(e) => {
                      changeActiveTab("pesquisar");
                    }}
                    style={{ width: "50%" }}
                  >
                    PESQUISAR
                  </Button>
                </ArrowTooltip>
                <ArrowTooltip title="shift+2">
                  <Button
                    color={horizontalTabs === "cadastrar" ? "info" : ""}
                    type="button"
                    data-toggle="tab"
                    className={classNames(
                      "btn-gp-padrao",
                      horizontalTabs === "cadastrar" ? "active" : ""
                    )}
                    onClick={(e) => {
                      changeActiveTab("cadastrar");
                      novoCadatro();
                    }}
                    style={{ width: "50%" }}
                  >
                    CADASTRAR
                  </Button>
                </ArrowTooltip>
              </ButtonGroup>
              <TabContent
                className="tab-space"
                activeTab={horizontalTabs}
                style={{ paddingBottom: "0px" }}
              >
                <TabPane tabId="pesquisar">
                  <Row>
                    <Col md="4">
                      <label>Nome</label>
                      <Input
                        id="nome"
                        type="text"
                        autoComplete="off"
                        value={pesquisa.nome}
                        onChange={(e) => handleOnChange(e, "nome", "PESQUISA")}
                        onKeyPress={(e) => handleKeyPress(e)}
                        autoFocus={true}
                        maxLength="30"
                      />
                    </Col>
                    <Col md="3">
                      <label>E-mail</label>
                      <Input
                        id="email"
                        type="text"
                        autoComplete="off"
                        value={pesquisa.email}
                        onChange={(e) => handleOnChange(e, "email", "PESQUISA")}
                        onKeyPress={(e) => handleKeyPress(e)}
                        maxLength="60"
                      />
                    </Col>
                    <Col md="3">
                      <label>CPF</label>
                      <InputCpfCnpj
                        id="cpf"
                        tipo="cpf"
                        autoComplete="off"
                        value={pesquisa.cpf}
                        onChange={(e) => handleOnChange(e, "cpf", "PESQUISA")}
                        onKeyPress={(e) => handleKeyPress(e)}
                        maxLength="11"
                      />
                    </Col>
                    <Col
                      md="1"
                      className="text-center"
                      style={{ paddingTop: "13px" }}
                    >
                      <Col className={width > 768 ? "text-left" : ""}>
                        <FormGroup check className="form-check-radio">
                          <Label check style={{ paddingRight: "5px" }}>
                            <Input
                              type="radio"
                              onChange={() => handleOnChangeCheck(0)}
                              checked={pesquisa.ativos === 0 ? true : false}
                            />
                            <span className="form-check-sign" />
                            Ativos
                          </Label>
                          <Label check style={{ paddingRight: "5px" }}>
                            <Input
                              type="radio"
                              onChange={() => handleOnChangeCheck(1)}
                              checked={pesquisa.ativos === 1 ? true : false}
                            />
                            <span className="form-check-sign" />
                            Inativos
                          </Label>
                        </FormGroup>
                      </Col>
                    </Col>
                  </Row>
                  <Row>
                    <CardBody>
                      <Col style={{ width: "100%" }}>
                        <ArrowTooltip title="Shift+L">
                          <Button
                            color="info"
                            style={
                              width > 550
                                ? { float: "right", width: "150px" }
                                : width < 550
                                  ? { marginLeft: "0px", width: "100%" }
                                  : {}
                            }
                            onClick={() => handleLimpar()}
                          >
                            Limpar
                          </Button>
                        </ArrowTooltip>
                        <ArrowTooltip title="Shift+P">
                          <Button
                            color="info"
                            style={
                              width > 550
                                ? { float: "right", width: "150px" }
                                : width < 550
                                  ? { marginLeft: "0px", width: "100%" }
                                  : {}
                            }
                            onClick={(e) => handlePesquisarUser(e)}
                          >
                            Pesquisar
                          </Button>
                        </ArrowTooltip>
                      </Col>
                    </CardBody>
                  </Row>
                  <div
                    style={
                      tableVisible
                        ? {
                            transition: "0.5s ease-in-out",
                            visibility: "visible",
                            opacity: "1",
                          }
                        : { visibility: "hidden", opacity: "0" }
                    }
                  >
                    <ReactTable
                      data={table}
                      filterable={false}
                      columns={[
                        {
                          Header: "Nome",
                          accessor: "nome",
                        },
                        {
                          Header: "E-mail",
                          accessor: "email",
                        },
                        {
                          Header: "CPF",
                          accessor: "cpf",
                        },
                        {
                          Header: "Data de cadastro",
                          accessor: "dtCadastro",
                        },
                        {
                          Header: "",
                          accessor: "actions",
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                      minRows={1}
                      showPaginationTop={false}
                      showPaginationBottom={true}
                      className="-striped -highlight"
                      noDataText=""
                      previousText="Anterior"
                      nextText="Próximo"
                      loadingText="Carregando"
                      pageText="Página"
                      ofText="de"
                      rowsText="linhas"
                    />
                  </div>
                </TabPane>
                <TabPane tabId="cadastrar">
                  <div style={{ marginTop: "-20px" }}>
                    <ReactWizard
                      steps={steps}
                      navSteps={true}
                      title=""
                      description=""
                      validate
                      resizable={true}
                      changeStep={changeActiveStep}
                      previousButtonText="Voltar"
                      finishButtonText="Finalizar"
                      nextButtonText="Avançar"
                      goToFirstStep={inicioCadastro}
                      goToNextStep={proximoCadastro}
                      finishButtonClick={() => finishButtonClick()}
                      finishButtonClasses={classNames(
                        "btn-wd btn-info",
                        {
                          finishButtonMin: width < 550,
                        },
                        {
                          finishButtonRight: !width < 550,
                        }
                      )}
                      nextButtonClasses={classNames(
                        "btn-wd btn-info",
                        {
                          nextButtonMin: width < 550,
                        },
                        {
                          nextButtonRight: !width < 550,
                        }
                      )}
                      previousButtonClasses={classNames(
                        "btn-wd",
                        {
                          previousButtonMin: width < 550,
                        },
                        {
                          previousButtonRight: !width < 550,
                        }
                      )}
                      progressbar
                      color="blue"
                    />
                  </div>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </div>
      {/* Modal Inativar*/}
      <Modal isOpen={modalInativar} toggle={toggleModalInativar}>
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalInativar}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">
            {userSelected.status === 0 ? "Inativar" : "Ativar"} usuário
          </h6>
        </div>
        <ModalBody>
          <Col className="text-center">
            {userSelected.status === 0 ? (
              <label>
                Tem certeza que deseja <b>inativar</b> esse funcionário da sua
                empresa?
              </label>
            ) : (
              <label>
                Tem certeza que deseja <b>ativar</b> esse funcionário em sua
                empresa?
              </label>
            )}
          </Col>
        </ModalBody>
        <CardBody>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Button
                color="danger"
                type="button"
                onClick={() => {
                  toggleModalInativar();
                }}
                style={{ width: "150px" }}
              >
                NÃO
              </Button>
            </Col>
            <Col className="text-center">
              <Button
                color="success"
                type="button"
                onClick={() => {
                  toggleModalInativar();
                  inativarUsuario();
                }}
                style={{ width: "150px" }}
              >
                SIM
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Modal>
      {/* Modal Inativar Usuario*/}
    </>
  );
};

export default Usuarios;

//valida telefone
export function ValidaTelefone(tel) {
    var exp = /\(\d{2}\)\s\d{4}-\d{4}/
    if (!exp.test(tel))
        return false
    return true
}

//valida CEP
export function  ValidaCep(cep) {
    var exp = /\d{5}-\d{3}/
    var exp2 = /\d{8}/
    if (!exp.test(cep) && !exp2.test(cep))
        return false
    return true
}

//valida data
export function ValidaData(data) {
    var exp = /\d{2}\/\d{2}\/\d{4}/
    if (!exp.test(data))
        return false
    return true
}

//valida o CPF digitado
export function ValidaCPF(cpf) {
    var exp = /\.|-/g
    cpf = cpf.toString().replace(exp, "");
    var digitoDigitado = Number(cpf.charAt(9) + cpf.charAt(10));
    var soma1 = 0, soma2 = 0;
    var vlr = 11;

    for (let i = 0; i < 9; i++) {
        soma1 += cpf.charAt(i) * (vlr - 1);
        soma2 += cpf.charAt(i) * vlr;
        vlr--;
    }
    soma1 = (((soma1 * 10) % 11) === 10 ? 0 : ((soma1 * 10) % 11));
    soma2 = (((soma2 + (2 * soma1)) * 10) % 11);

    var digitoGerado = (soma1 * 10) + soma2;
    if (digitoGerado !== digitoDigitado)
        return false
    return true
}

//valida o CNPJ digitado
export function ValidaCNPJ(cnpj) {
    var valida = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    var dig1 = 0;
    var dig2 = 0;

    var exp = /\.|-|\//g
    cnpj = cnpj.toString().replace(exp, "");
    var digito = Number(cnpj.charAt(12) + cnpj.charAt(13));

    for (let i = 0; i < valida.length; i++) {
        dig1 += (i > 0 ? (cnpj.charAt(i - 1) * valida[i]) : 0);
        dig2 += cnpj.charAt(i) * valida[i];
    }
    dig1 = (((dig1 % 11) < 2) ? 0 : (11 - (dig1 % 11)));
    dig2 = (((dig2 % 11) < 2) ? 0 : (11 - (dig2 % 11)));

    if (((dig1 * 10) + dig2) !== digito)
        return false
    return true
}

//valida email digitado
export function ValidaEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase()))
        return false
    return true
}
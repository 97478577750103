import axios from 'axios';
import api from "~/Util/api.js";
import history from "~/Util/history";
import { ValidarToken } from "~/controller/controllerLogin";

/*-----------------------------------------------------------------------
 * Valida se token está expirado, caso retorne 401 ou 403
 * Direciona para tela de Login
*-----------------------------------------------------------------------*/ 
export async function isAuth() {
    var tokenAuth = localStorage.getItem('TOKEN_KEY');
    //var usuario = localStorage.getItem('USUARIO');
    try {
        //Chama API e envia TOKEN para verificar se tá expirado
        if (tokenAuth !== "" && tokenAuth !== null){
            const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
            var urlValidar = api.urlIntegradorApi + api.urlValidarToken + `${tokenAuth}`;
            const response = await axios.get(urlValidar, {
                headers: { 'Authorization': bearerAuth }
            });    
            if (response.status === 200 && tokenAuth) {
                return;
            }
            else {
                history.push('/auth/login');
                return;
            }    
        }else{
            history.push('/auth/login');
            return;
        }
    }
    catch (e) {
        history.push('/auth/login');
        return;
    }
};

/* export async function isAuth() {
    var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
    var usuario = localStorage.getItem('USUARIO');
    try {
        const response = await axios.get(api.urlCadastro + 'api/v1/usuario/token', {
            headers: { Authorization: basicAuth }
        });
        if (response.status === 200 && usuario) {
            return;
        }
        else {
            history.push('/auth/login');
            return;
        }
    }
    catch (e) {
        history.push('/auth/login');
        return;
    }
}; */
import { combineReducers } from 'redux'

import sistema from './sistemaStore'
import notificacao from './notificacaoStore'
import login from './loginStore'
import EMPRESA from './Cadastros/empresaStore'
import PESQUISA_USUARIOS from './Pesquisas/pesqUsuarioStore'
import DASHBOARD from './Dashboard/dashboardStore'
//import grupos from './gruposAcessoStore'
import usuarios from './usuariosStore'
//import clientes from './clientesStore'
//import fornecedores from './fornecedoresStore'
//import transportadoras from './transportadorasStores'
//import gruposProduto from './gruposProdutoStore'
//import subgruposProduto from './subgruposProdutoStore'
//import produtos from './produtosStore'
//import servicos from './servicosStore'
//import grades from './gradesStore'
//import cests from './cestsStore'
//import ncms from './ncmsStore'
import ICMSs from './icmsStore'
//import classificacoesTributarias from './classificacoesTributariasStore'
//import cfops from './cfopsStore'
//import ipis from './ipisStore'
//import enquadramentosIPI from './enquadramentosIPIStore'
//import pisCofins from './pisCofinsStore'

export default combineReducers({
    notificacao,
    login,
    sistema,
    EMPRESA,
    PESQUISA_USUARIOS,
    DASHBOARD,
    //grupos,
    usuarios,
    //clientes,
    //fornecedores,
    //transportadoras,
    //gruposProduto,
    //subgruposProduto,
    //produtos,
    //servicos,
    //grades,
    //cests,
    //ncms,
    ICMSs,
    //classificacoesTributarias,
    //cfops,
    //ipis,
    //enquadramentosIPI,
    //pisCofins,
})

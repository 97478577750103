import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ImageUpload from "~/components/CustomUpload/ImageUpload.jsx";
import * as controllerUsuarios from "~/controller/controllerUsuarios";
import { Switch } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from "~/components/Tooltip/ArrowTooltip";
import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,
  Modal,
  ModalBody,
} from "reactstrap";

import { InputCpfCnpj } from "~/components/ErnComponets/ErnComponets";

const Usuario = (props) => {
  const notificacao = useSelector((state) => state.notificacao);
  const { pesquisa, cadastro, invalido } = useSelector(
    (state) => state.usuarios
  );
  const { empresa } = useSelector((state) => state.login.usuario);
  const [modalUsuarioExite, setModalUsuarioExite] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.salvar) Salvar();
  }, [props.salvar]);

  function onChange(e, stateName) {
    dispatch({
      idStore: "USUARIOS",
      type: "CADASTRO",
      campo: stateName,
      value: e.target.value,
    });
    dispatch({
      idStore: "USUARIOS",
      type: "INVALIDO",
      campo: stateName,
      value: false,
    });
  }

  async function Salvar() {
    dispatch({
      idStore: "USUARIOS",
      type: "CADASTRO",
      campo: "codEmpresa",
      value: empresa.codEmpresa,
    });

    var usuarioExiste = await controllerUsuarios.SalvarUsuario(
      notificacao,
      dispatch,
      cadastro,
      empresa.codEmpresa
    );

    if (usuarioExiste) {
      toggleModalUsuarioExiste();
    } else if (usuarioExiste === false) {
      setProximoCadastro(true);

      setTimeout(() => {
        setProximoCadastro(false);
      }, 100);
    }
  }

  function onChangeAtivo(e) {
    if (cadastro.codFor !== "") {
      controllerUsuarios.InativarUsuario(
        notificacao,
        dispatch,
        cadastro,
        empresa.codEmpresa,
        pesquisa
      );
    }
    dispatch({
      idStore: "USUARIOS",
      type: "CADASTRO",
      campo: "status",
      value: e.target.checked ? 0 : 1,
    });
  }

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore: "USUARIOS", type: "CLEAN_CADASTRO" });
    dispatch({ idStore: "USUARIOS", type: "CLEAN_INVALIDO" });
  }

  function toggleModalUsuarioExiste() {
    setModalUsuarioExite(!modalUsuarioExite);
  }

  function vinculaUsuario() {
    var retorno = controllerUsuarios.VinculaUsuarioEmpresa(
      notificacao,
      dispatch,
      cadastro.cpf,
      empresa.codEmpresa
    );

    if (retorno) {
      setProximoCadastro(true);

      setTimeout(() => {
        setProximoCadastro(false);
      }, 100);
    }
  }

  function setProximoCadastro(valor) {
    dispatch({ idStore: "USUARIOS", type: "PROXIMO_CADASTRO", value: valor });
  }

  function SetImgPerfil(value) {
    dispatch({
      idStore: "USUARIOS",
      type: "CADASTRO",
      campo: "foto",
      value: value,
    });
  }

  return (
    <>
      <Row className="justify-content-center">
        <Col>
          <h4 className="info-text">Preencha com as informações do usuário</h4>
          <CardBody>
            <Row className="justify-content-center">
              <Col md="10" className="text-right">
                <Switch
                  componentleft={
                    <label
                      className={
                        cadastro.status === 0
                          ? `text-success text-center`
                          : "text-warning text-center"
                      }
                    >
                      {cadastro.status === 0 ? "Ativo" : "Inativo"}
                    </label>
                  }
                  checked={cadastro.status === 0}
                  onChange={(e) => onChangeAtivo(e)}
                  disabled={cadastro.codUser === ""}
                />
              </Col>

              <Col md="5">
                <Row>
                  <Col>
                    <h5>Informações básicas</h5>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <label>
                      Nome <span className="error">*</span>
                    </label>
                    <FormGroup className={invalido.nome ? "has-danger" : ""}>
                      <Input
                        id="nomeUsuario"
                        name="nome"
                        type="text"
                        value={cadastro.nome}
                        onChange={(e) => onChange(e, "nome")}
                        disabled={cadastro.codUser !== "" ? true : false}
                        maxLength="55"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <label>Apelido</label>
                    <Input
                      name="apelido"
                      type="text"
                      value={cadastro.apelido}
                      onChange={(e) => onChange(e, "apelido")}
                      disabled={cadastro.codUser !== "" ? true : false}
                      maxLength="15"
                    />
                    <br></br>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <label>
                      CPF <span className="error">*</span>
                    </label>
                    <FormGroup className={invalido.cpf ? "has-danger" : ""}>
                      <InputCpfCnpj
                        name="cpf"
                        tipo="cpf"
                        value={cadastro.cpf}
                        onChange={(e) => onChange(e, "cpf")}
                        disabled={cadastro.codUser !== "" ? true : false}
                        maxLength="14"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <label>
                      E-mail pessoal<span className="error">*</span>
                    </label>
                    <FormGroup className={invalido.email ? "has-danger" : ""}>
                      <Input
                        name="email"
                        type="text"
                        value={cadastro.email}
                        onChange={(e) => onChange(e, "email")}
                        disabled={cadastro.codUser !== "" ? true : false}
                        maxLength="60"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="4" style={{ marginTop: "-0px" }}>
                <Row className="justify-content-center">
                  <Col className="text-center">
                    <h5>Foto de Perfil</h5>
                    <ImageUpload
                      changeBtnClasses="btn-simple btn-padrao"
                      addBtnClasses="btn-simple"
                      removeBtnClasses="btn-simple btn-padrao"
                      changeBtnColor="info"
                      addBtnColor="info"
                      avatar
                      imagem={cadastro.foto}
                      onChange={SetImgPerfil}
                      disabled={cadastro.codUser !== ""}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-center">
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <ArrowTooltip title="Shift+L">
                <Button
                  id="btnNovoUsuario"
                  className="btn-padrao"
                  color="default"
                  onClick={(e) => Limpar(e)}
                >
                  {cadastro.codUser !== "" ? "Novo" : "Limpar"}
                </Button>
              </ArrowTooltip>

              {cadastro.codUser === "" ? (
                <ArrowTooltip title="Shift+S">
                  <Button
                    className="btn-padrao"
                    color="info"
                    onClick={(e) => {
                      Salvar();
                      e.preventDefault();
                    }}
                  >
                    Salvar
                  </Button>
                </ArrowTooltip>
              ) : (
                <Button
                  className="btn-padrao"
                  color="info"
                  onClick={(e) => {
                    Salvar();
                    e.preventDefault();
                  }}
                  disabled={cadastro.codUser !== ""}
                >
                  Salvar
                </Button>
              )}
            </Col>
          </CardFooter>
        </Col>
      </Row>

      {/* Modal UsuarioExite */}
      <Modal isOpen={modalUsuarioExite} toggle={toggleModalUsuarioExiste}>
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalUsuarioExiste}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">Atenção</h6>
        </div>
        <ModalBody>
          <Col className="text-center">
            <label>
              Esse usuário já está cadastrado no sistema. Deseja vinculá-lo à
              sua empresa?
            </label>
          </Col>
        </ModalBody>
        <CardBody>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Button
                color="danger"
                type="button"
                onClick={() => {
                  toggleModalUsuarioExiste();
                }}
                style={{ width: "150px" }}
              >
                NÃO
              </Button>
            </Col>
            <Col className="text-center">
              <Button
                color="success"
                type="button"
                onClick={() => {
                  toggleModalUsuarioExiste();
                  vinculaUsuario();
                }}
                style={{ width: "150px" }}
              >
                SIM
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Modal>
      {/* Modal UsuarioExite*/}
    </>
  );
};

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salvar: false,
    };
  }

  focusInput = () => {
    setTimeout(() => {
      if (this.props.cadastro.codUser !== "") {
        document.getElementById("btnNovoUsuario").focus();
      } else {
        document.getElementById("nomeUsuario").focus();
      }
    }, 100);
  };

  isValidated = () => {
    if (this.props.cadastro.codUser !== "") {
      this.setState({ salvar: false });
      return true;
    }
    this.setState({ salvar: true }, () => {
      this.setState({ salvar: false });
    });
    return false;
  };

  render() {
    return <Usuario salvar={this.state.salvar} />;
  }
}

export default Wizard;

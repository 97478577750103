import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertar } from "~/Util/alertar";
import ReactWizard from "~/components/Wizard/Wizard";
//import ReactTable from "react-table";
import ReactTable from "react-table-6";
import * as controllerICMSs from "~/controller/controllerICMSs";
import classNames from "classnames";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { Select } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from "~/components/Tooltip/ArrowTooltip";
import { maskICMS } from "~/Util/mascaras";
import { useHotkeys } from "react-hotkeys-hook";
import {
  Card,
  Col,
  Button,
  ButtonGroup,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Row,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap";

// wizard steps
import Step1 from "~/views/ICMSs/CadastroSteps/ICMS.jsx";

var steps = [
  {
    stepName: "ICMS",
    stepIcon: "tim-icons icon-notes",
    component: Step1,
    stepProps: {},
  },
];

const idStore = "ICMS";

const ICMSs = () => {
  const notificacao = useSelector((state) => state.notificacao);
  const { codEmpresa } = useSelector((state) => state.login.usuario.empresa);
  const { ICMS, ICMSCopia, pesquisa, ICMSs, inicioCadastro, regimes } =
    useSelector((state) => state.ICMSs);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [dataTable, setDataTable] = useState([]);
  const [horizontalTabs, setHorizontalTabs] = useState("pesquisar");
  const [modalExcluir, setModalExcluir] = useState(false);
  const [icmsSelected, seticmsSelected] = useState({ status: 0 });
  const [table, setTable] = useState(renderTable([]));

  const [tableVisible, setTableVisible] = useState(false);

  const [width, setWidht] = useState(window.innerWidth);

  useEffect(() => {
    setTable(renderTable());
  }, [dataTable, loading]);

  function renderTable() {
    return dataTable.map((obj, key) => {
      return {
        ...dataTable[key],
        actions: (
          <div className="actions-right">
            {!loading ? (
              <ArrowTooltip title="Editar">
                <Button
                  onClick={() => {
                    handleEditarCadastro(obj);
                  }}
                  size="sm"
                  className={classNames("btn-icon btn-link")}
                  color="success"
                >
                  <Icon icon="edit" className="fa-lg" />
                </Button>
              </ArrowTooltip>
            ) : (
              <Button
                onClick={() => {
                  handleEditarCadastro(obj);
                }}
                size="sm"
                className={classNames("btn-icon btn-link")}
                color="success"
                disabled={true}
              >
                <Icon icon="edit" className="fa-lg" />
              </Button>
            )}
            <ArrowTooltip title="Excluir">
              <Button
                onClick={() => {
                  handleExcluir(obj);
                }}
                className="btn-icon btn-link"
                color="danger"
              >
                <Icon icon="trash-alt" className="fa-lg" />
              </Button>
            </ArrowTooltip>
          </div>
        ),
      };
    });
  }

  async function handleEditarCadastro(obj) {
    setLoading(true);
    let aux = regimes.find((desc) => desc.codRegime === obj.codRegime);
    dispatch({ idStore, type: "ICMS", campo: "regime", value: aux.descricao });
    if (!loading) {
      await controllerICMSs.GetICMS(
        notificacao,
        dispatch,
        codEmpresa,
        obj.codICMS,
        () => {
          setHorizontalTabs("cadastrar");
          setInicioCadastro(true);
          dispatch({ idStore, type: "CLEAN_INVALIDO" });
        }
      );
      setLoading(false);
    }
  }

  function setInicioCadastro(valor) {
    dispatch({ idStore, type: "INICIO_CADASTRO", value: valor });
  }

  async function handleExcluir(obj) {
    seticmsSelected(obj);
    toggleModalExcluir();
  }

  function toggleModalExcluir() {
    setModalExcluir(!modalExcluir);
  }

  async function novoCadatro() {
    setInicioCadastro(true);
    dispatch({ idStore, type: "CLEAN_ICMS" });
    dispatch({ idStore, type: "CLEAN_INVALIDO" });
  }

  function changeActiveTab(tabName) {
    setHorizontalTabs(tabName);
    if (tabName === "pesquisar") {
      setTimeout(() => {
        document.getElementById("numeroICMS").focus();
      }, 100);
    }
  }

  function handleOnChange(value, campo) {
    dispatch({ idStore, type: "PESQUISA", campo, value });
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      controllerICMSs.Pesquisar(notificacao, dispatch, codEmpresa, pesquisa);
    }
  }

  function onChangeRegime(value) {
    dispatch({ idStore, type: "PESQUISA", campo: "codRegime", value: value });
    controllerICMSs.Pesquisar(notificacao, dispatch, codEmpresa, {
      ...pesquisa,
      codRegime: value,
    });
    if (value === "0") {
      dispatch({ idStore, type: "REGIMES_PESQ", value: [] });
    } else {
      controllerICMSs.GetRegimes(
        notificacao,
        dispatch,
        codEmpresa,
        { ...pesquisa, codRegime: value },
        (value) => {
          dispatch({ idStore, type: "REGIMES_PESQ", value });
          dispatch({
            idStore,
            type: "PESQUISA",
            campo: "codRegime",
            value: "0",
          });
        }
      );
    }
  }

  function handlePesquisar() {
    controllerICMSs.Pesquisar(notificacao, dispatch, codEmpresa, pesquisa);
  }

  function handleLimpar() {
    dispatch({ idStore, type: "CLEAN_SEARCH" });
  }

  async function finishButtonClick() {
    var error;
    if (ICMS.codICMS === "") {
      error = await controllerICMSs.CreateICMS(
        notificacao,
        dispatch,
        codEmpresa,
        ICMS
      );
      handleError(error);
    } else {
      error = await controllerICMSs.UpdateICMS(
        notificacao,
        dispatch,
        codEmpresa,
        ICMS,
        ICMSCopia
      );
      handleError(error);
    }

    function handleError(error) {
      if (!error) {
        dispatch({ idStore, type: "CLEAN_ICMS" });
        alertar(
          notificacao,
          "br",
          "Cadastro concluído",
          "success",
          "icon-check-2",
          2
        );
        setInicioCadastro(true);
      }
    }
    return error;
  }

  function excluir() {
    controllerICMSs.Excluir(
      notificacao,
      dispatch,
      codEmpresa,
      icmsSelected,
      pesquisa
    );
  }

  function tableData(data) {
    return data.map((prop, key) => {
      let retorno = regimes.find(
        (desc) => desc.codRegime === prop["codRegime"]
      );
      let desc = "";
      if (retorno) desc = retorno.descricao;
      return {
        id: key,
        codICMS: prop["codIcms"],
        ICMS: maskICMS(prop["icms"]),
        descricao: prop["descricao"],
        codRegime: prop["codRegime"],
        regime: desc,
        status: prop["status"],
      };
    });
  }

  useEffect(() => {
    setDataTable(tableData(ICMSs));
    if (ICMSs.length > 0) {
      setTableVisible(true);
    } else {
      setTableVisible(false);
    }
  }, [ICMSs]);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    controllerICMSs.GetRegimes(notificacao, dispatch, codEmpresa);
    dispatch({ idStore, type: "REGIMES_PESQ", value: [] });
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  function updateWidth() {
    setWidht(window.innerWidth);
  }

  useEffect(() => {
    steps.forEach((step) => {
      step.stepProps = { ...step.stepProps, notificacao };
    });
  }, [notificacao]);

  useEffect(() => {
    steps.forEach((step) => {
      step.stepProps = { ...step.stepProps, ICMS: ICMS };
    });
    setInicioCadastro(false);
  }, [ICMS]);

  useEffect(() => {
    if (inicioCadastro) setInicioCadastro(false);
  }, [inicioCadastro]);

  /* 
  


  
  
  

  useHotkeys(
    "shift+1",
    () => {
      changeActiveTab("pesquisar");
    },
    []
  );

  useHotkeys(
    "shift+2 ",
    () => {
      changeActiveTab("cadastrar");
      novoCadatro();
    },
    []
  );

  useHotkeys(
    "shift+p",
    () => {
      if (horizontalTabs === "pesquisar") {
        controllerICMSs.Pesquisar(notificacao, dispatch, codEmpresa, pesquisa);
      }
    },
    [codEmpresa, pesquisa]
  );

  useHotkeys(
    "shift+l",
    () => {
      if (horizontalTabs === "pesquisar") {
        handleLimpar();
      } else if (horizontalTabs === "cadastrar") {
        dispatch({ idStore, type: "CLEAN_ICMS" });
        dispatch({ idStore, type: "CLEAN_INVALIDO" });
      }
    },
    [horizontalTabs]
  );

  useHotkeys(
    "shift+s",
    () => {
      if (horizontalTabs === "cadastrar") {
        if (ICMS.codICMS === "") {
          controllerICMSs.CreateICMS(notificacao, dispatch, codEmpresa, ICMS);
        } else {
          controllerICMSs.UpdateICMS(
            notificacao,
            dispatch,
            codEmpresa,
            ICMS,
            ICMSCopia
          );
        }
      }
    },
    [horizontalTabs, codEmpresa, ICMS, ICMSCopia]
  );








  


 



*/

  return (
    <>
      <div className="content">
        <Col className="mr-auto ml-auto" md="11">
          <Card>
            <CardHeader className="text-center">
              <CardTitle tag="h3">
                Imposto sobre Circulação de Mercadorias e Serviços
              </CardTitle>
              <TabContent
                className="tab-space"
                activeTab={horizontalTabs}
                style={{ marginTop: "-30px", marginBottom: "-70px" }}
              >
                <TabPane tabId="pesquisar">
                  <h4>Pesquisa de ICMS</h4>
                </TabPane>

                <TabPane tabId="cadastrar">
                  <h4>Cadastro de ICMS</h4>
                </TabPane>
              </TabContent>
            </CardHeader>

            <CardBody>
              <ButtonGroup style={{ width: "100%" }}>
                <ArrowTooltip title="shift+1">
                  <Button
                    color={horizontalTabs === "pesquisar" ? "info" : ""}
                    type="button"
                    data-toggle="tab"
                    className={classNames(
                      "btn-gp-padrao",
                      horizontalTabs === "pesquisar" ? "active" : ""
                    )}
                    onClick={() => {
                      changeActiveTab("pesquisar");
                    }}
                    style={{ width: "50%" }}
                  >
                    PESQUISAR
                  </Button>
                </ArrowTooltip>
                <ArrowTooltip title="shift+2">
                  <Button
                    color={horizontalTabs === "cadastrar" ? "info" : ""}
                    type="button"
                    data-toggle="tab"
                    className={classNames(
                      "btn-gp-padrao",
                      horizontalTabs === "cadastrar" ? "active" : ""
                    )}
                    onClick={() => {
                      changeActiveTab("cadastrar");
                      novoCadatro();
                    }}
                    style={{ width: "50%" }}
                  >
                    CADASTRAR
                  </Button>
                </ArrowTooltip>
              </ButtonGroup>
            </CardBody>
            <TabContent
              className="tab-space"
              activeTab={horizontalTabs}
              style={{ paddingBottom: "0px" }}
            >
              {/* INICIO TabPesquisa */}
              <TabPane tabId="pesquisar">
                <Row>
                  <Col lg="3" md="3">
                    <label>Regime</label>
                    <Select
                      id="pesqRegimesSelect"
                      first={"Todos"}
                      value={{ value: pesquisa.codRegime }}
                      select={{ value: "codRegime", label: "descricao" }}
                      options={regimes}
                      isSearchable={true}
                      onChange={(value) =>
                        onChangeRegime(value.value, "codRegime")
                      }
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="2" md="3">
                    <label>ICMS</label>
                    <Input
                      id="numeroICMS"
                      type="text"
                      autoComplete="off"
                      value={pesquisa.numeroICMS}
                      onChange={(e) =>
                        handleOnChange(maskICMS(e.target.value), e.target.id)
                      }
                      onKeyPress={(e) => handleKeyPress(e)}
                      autoFocus={true}
                      maxLength="5"
                    />
                  </Col>

                  <Col lg="7" md="3">
                    <label>Descricao</label>
                    <Input
                      id="descricao"
                      type="text"
                      autoComplete="off"
                      value={pesquisa.descricao}
                      onChange={(e) =>
                        handleOnChange(e.target.value, e.target.id)
                      }
                      onKeyPress={(e) => handleKeyPress(e)}
                      maxLength="50"
                    />
                  </Col>
                </Row>
                <Row>
                  <CardBody>
                    <Col style={{ width: "100%" }}>
                      <ArrowTooltip title="shift+L">
                        <Button
                          color="info"
                          style={
                            width > 550
                              ? { float: "right", width: "150px" }
                              : width < 550
                                ? { marginLeft: "0px", width: "100%" }
                                : {}
                          }
                          onClick={() => handleLimpar()}
                        >
                          Limpar
                        </Button>
                      </ArrowTooltip>
                      <ArrowTooltip title="shift+S">
                        <Button
                          color="info"
                          style={
                            width > 550
                              ? { float: "right", width: "150px" }
                              : width < 550
                                ? { marginLeft: "0px", width: "100%" }
                                : {}
                          }
                          onClick={() => handlePesquisar()}
                        >
                          Pesquisar
                        </Button>
                      </ArrowTooltip>
                    </Col>
                  </CardBody>
                </Row>
                {/* INCIO TabPesquisa-GRADE */}
                <div
                  style={
                    tableVisible
                      ? {
                          transition: "0.5s ease-in-out",
                          visibility: "visible",
                          opacity: "1",
                        }
                      : { visibility: "hidden", opacity: "0" }
                  }
                >
                  <ReactTable
                    data={table}
                    filterable={false}
                    columns={[
                      {
                        Header: "ICMS",
                        accessor: "ICMS",
                      },
                      {
                        Header: "Descrição",
                        accessor: "descricao",
                      },
                      {
                        Header: "Regime",
                        accessor: "regime",
                      },
                      {
                        Header: "",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    minRows={1}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    noDataText=""
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando"
                    pageText="Página"
                    ofText="de"
                    rowsText="linhas"
                  />
                </div>
                {/* FIM TabPesquisa-GRADE */}
              </TabPane>
              {/* FIM TabPesquisa */}
              {/* INICIO TabCadastro */}
              <TabPane tabId="cadastrar">
                <div style={{ marginTop: "-20px" }}>
                  <ReactWizard
                    steps={steps}
                    navSteps={true}
                    title=""
                    description=""
                    validate
                    resizable={true}
                    previousButtonText="Voltar"
                    finishButtonText="Finalizar"
                    nextButtonText="Avançar"
                    goToFirstStep={inicioCadastro}
                    finishButtonClick={() => finishButtonClick()}
                    finishButtonClasses={classNames(
                      "btn-wd btn-info",
                      {
                        finishButtonMin: width < 550,
                      },
                      {
                        finishButtonRight: !width < 550,
                      }
                    )}
                    nextButtonClasses={classNames(
                      "btn-wd btn-info",
                      {
                        nextButtonMin: width < 550,
                      },
                      {
                        nextButtonRight: !width < 550,
                      }
                    )}
                    previousButtonClasses={classNames(
                      "btn-wd",
                      {
                        previousButtonMin: width < 550,
                      },
                      {
                        previousButtonRight: !width < 550,
                      }
                    )}
                    progressbar
                    color="blue"
                  />
                </div>
              </TabPane>
            </TabContent>
            {/* FIM TabCadastro */}
          </Card>
        </Col>
      </div>

      {/* Modal Excluir */}

      <Modal isOpen={modalExcluir} toggle={toggleModalExcluir}>
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalExcluir}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">Excluir ICMS</h6>
        </div>

        <CardBody>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Button
                color="danger"
                type="button"
                onClick={() => {
                  toggleModalExcluir();
                }}
                style={{ width: "150px" }}
              >
                NÃO
              </Button>
            </Col>
            <Col className="text-center">
              <Button
                color="success"
                type="button"
                onClick={() => {
                  toggleModalExcluir();
                  excluir();
                }}
                style={{ width: "150px" }}
              >
                SIM
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Modal>

      {/* Modal Excluir*/}
    </>
  );
};

export default ICMSs;

import axios from 'axios';
import api from "~/Util/api.js";

var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + api.apiPass);
export default function getTelas(dispatch) {
    axios.get(api.urlCadastro + 'api/v1/telas', {
        headers: { Authorization: basicAuth }
    }).then(response => {
        dispatch({ idStore: 'SISTEMA', type: 'TELAS', value: response.data.records })
    })
}